%sprite-icon {
    a:hover,
    &.active {
        a:before,
        &:before {
            background-position: center bottom;
        }
    }
}

%nav-transition {
    -webkit-transition: opacity 300ms, background 50ms;
    -moz-transition: opacity 300ms, background 50ms;
    -o-transition: opacity 300ms, background 50ms;
    transition: opacity 300ms, background 50ms;
}

%generic-transition-all {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

%quick-generic-transition-all {
    -webkit-transition: all 100ms;
    -moz-transition: all 100ms;
    -o-transition: all 100ms;
    transition: all 100ms;
}

%center-horizontal-absolute-position {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

%center-vertical-absolute-position {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

%center-vertical-horizontal-absolute-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

%font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
}

%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

%no-select {
    -webkit-touch-callout: none; // iOS Safari
    -webkit-user-select: none;   // Chrome/Safari/Opera
    -khtml-user-select: none;    // Konqueror
    -moz-user-select: none;      // Firefox
    -ms-user-select: none;       // Internet Explorer/Edge
    user-select: none;
}

%background-clip{
    -moz-background-clip: padding; 
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content
    }
    :-moz-placeholder {
        @content
    }
    ::-moz-placeholder {
        @content
    }
    :-ms-input-placeholder {
        @content
    }
}

@mixin pattern-stripes($bg-color, $line-color, $line-width, $spacing, $direction: 45deg) {
    background-image: repeating-linear-gradient(
                    $direction,
                    $line-color,
                    $line-color $line-width + px,
                    $transparent ($line-width) + px,
                    $transparent (($spacing)) + px
    );

    background-image:  -webkit-repeating-linear-gradient(
                    $direction,
                    $line-color,
                    $line-color $line-width + px,
                    $transparent ($line-width) + px,
                    $transparent (($spacing)) + px
    );

    background-image: -ms-repeating-linear-gradient(
                    $direction,
                    $line-color,
                    $line-color $line-width + px,
                    $transparent ($line-width) + px,
                    $transparent (($spacing)) + px
    );

    background-image: -moz-repeating-linear-gradient(
                    $direction,
                    $line-color,
                    $line-color $line-width + px,
                    $transparent ($line-width) + px,
                    $transparent (($spacing)) + px
    );

    background-image: -o-repeating-linear-gradient(
                    $direction,
                    $line-color,
                    $line-color $line-width + px,
                    $transparent ($line-width) + px,
                    $transparent (($spacing)) + px
    );

    background-color:$bg-color;
    background-size: 100px 100px;

    //background-position-x: ($line-width * $spacing) + px;
}

@mixin rotate($direction: 0deg) {
    -ms-transform: rotate($direction); /* IE 9 */
    -webkit-transform: rotate($direction); /* Chrome, Safari, Opera */
    transform: rotate($direction);
    
    -ms-transform-origin: 0 20px 0 20px;
    -webkit-transform-origin: 0 20px 0 20px;
    transform-origin: 0 20px 0 20px;
}