/**
 * Colors
 */

// Base colors
$gray-lighter   : #F0F0F0; //#f7f7f7;
$gray-light     : #C5C5C5;
$gray           : #828082;
$gray-darkest   : #3B3B3B;
$gray-dark      : #999;
$gray-medium    : #BBC4C9;
$gray-disabled  : #F3F3F3;

$gray-blue-dark : #243041;

$gray-blue-light-lighter-1 : #C0D1DC;
$gray-blue-light           : #9FBBCD;
$gray-blue-light-darker-1  : #839FB1;

$teal-lighter-1 : #8CD3C6;
$teal           : #46C4AD;
$teal-darker-1  : #35AC96;

$blue-lighter-1 : #92CAE7;
$blue           : #51ADDF;
$blue-darker-1  : #3895C7;

$blue-dark-lighter-1 : #579BB5;
$blue-dark           : #2476A3;
$blue-dark-darker-1  : #0F5F7E;

$purple-lighter-1 : #E1E1EE;
$purple           : #9D9EC9;
$purple-darker-1  : #898AB1;

$green          : #42C87F;
$green-dark     : #26A961;
$white          : #fff;
$black          : #000;

$red-lighter-1 : #BD4C5D;
$red           : #c32d46;
$red-darker-1  : #973341;

$input-border   : #CDCDCD;
$placeholder    : #838383;
$item-border    : #D6D6D6;

$edit-button              : #00C2A0;
$edit-button-border       : $teal-darker-1;
$edit-button-hover        : $teal;
$edit-button-border-hover : $teal-lighter-1;

$transparent : rgba(0,0,0,0);

$primary-color-lighter  : rgba(122,199,242,0.35);
$primary-color          : $blue;
$primary-color-darker   : #3C92C1;
$primary-highlights     : $white;

$secondary-color-lighter : $blue;
$secondary-color         : $blue-dark;
$secondary-color-darker  : #256D94;
$secondary-highlights    : $white;

$disabled-color-lighter : $gray-lighter;
$disabled-color         : $gray-light;
$disabled-color-darker  : #AAAEB2;

$success-color          : #39D17E;
$success-color-darker   : #35AF6B;

$warning-color          : #FCB00A;
$warning-color-darker   : #D69608;

$danger-color           : $red; //#C32D46;
$danger-color-darker    : $red-darker-1; //#973341;
$danger-hover           : $red-lighter-1; //#BD4C5D;

$mainBlackTextColor   : #1F2633;
$mainErrorColor       : $red; //#C0392B;
$mainDeleteColor      : $danger-color;
$disabledTabColor     : #BFC4C6;
$inpuPlaceholderColor : #9f9f9f;
$selectedGroupColor   : #2576A3;

$base-background    : #e5edf0;
$base-type-dark-color   : #535353;

//Buttons
$save-bar-button-border : #4694BE;
$icon-button-left-padding   :  47px;

$form-button-hover  :   #61B5E3;
$form-button-border-hover   :   #4498C6;

// Backgrounds
$app-bg          : $gray-lighter;
$nav-bg          : $gray-blue-dark;
$toolbar-bg      : $white;
$panel-bg        : $white;
$header-bg       : $blue;
$subheader-bg    : $blue-dark;
$grey-backgorund : #DFE7EA;

// Links
$link-color                : $black;
$link-hover-color          : $black;
$link-active-color         : $black;

$nav-link-color            : $gray-dark;
$nav-link-hover-color      : $white;
$nav-link-active-color     : $white;

$toolbar-link-color        : $gray;
$toolbar-link-hover-color  : $blue;
$toolbar-link-active-color : $blue;

$filter-link-color        : $white;
$filter-link-hover-color  : $white;
$filter-link-active-color : $white;
$filter-link-bg-color     : $blue;
$filter-link-alt-bg-color : $blue-dark;



/**
 * Typography
 */

$font-serif: 'Open Sans', 'Lucida Grande', sans-serif;


/**
 * TBC
 */

$filter-toolbar-height: 80px;



/**
 * Variables
 */

// Containers
$min-width-container       : 980px;
$main-outer-container      : 980px;


// Padding
$padding-base-horizontal  : 10px;
$padding-base-vertical    : 15px; // Ändra inte till samma som $padding-large-vertical, använd den variabeln ist!

$padding-large-horizontal : 20px;
$padding-large-vertical   : 30px;

$padding-small-horizontal : 5px;
$padding-small-vertical   : 8px;

$padding-content          : 3%;

$padding-side-around-main-content : 25px;
$padding-top-around-main-content : 40px;


// Nav
$nav-width: 100px;
$nav-sub-width: 100px;

/**
 * Datepicker
 */

$picker-wrapper-width  : 240px;
$picker-wrapper-height : auto;
$picker-top-offset     : 30px;
$picker-item-width     : round(($picker-wrapper-width - ($padding-base-horizontal * 2)) / 7) - 1;

$picker-selected-color   : $gray-blue-dark;
$picker-hover-color      : $blue;
$picker-day-text-color   : $white;
$picker-disabled-color   : $gray-light;

$total-width-of-simple-datepicker: 300px; // Try not to commit unbuildable code!


/**
 * CarPools
 */

$carpool-demo-color: #63c2bc;
$carpool-courtesy-color: #42658c;
$carpool-used-color: #63C28B;


/**
 * Booking
 */

// Customer booking
$booking-customer-color-border : $blue-darker-1;
$booking-customer-color        : $blue; //#35D4B0;
$booking-customer-color-active : $blue-lighter-1;

// Staff booking
$booking-personal-color-border : $teal-darker-1;
$booking-personal-color        : $teal; //#49C1D9;
$booking-personal-color-active : $teal-lighter-1;

/// Event booking
$booking-flag-color-border : $purple-darker-1;
$booking-flag-color        : $purple; //#9B5DB5;
$booking-flag-color-label  : $white; //#9B5DB5;
$booking-flag-color-active : $purple;

// Service booking
$booking-service-color-border : $warning-color-darker;
$booking-service-color        : $warning-color; //#6F91C2;
$booking-service-color-label  : $white; //#6F91C2;
$booking-service-color-active : $warning-color;

// Overdue booking
$booking-overdue-color        : $red;
$booking-overdue-color-active : $red-lighter-1;


/**
 * Icons
 */

$ico-toolbar-calendar : 35px;


/**
 * Timeline
 */
$height-of-timeline-slots : 100px;

$timeline-hud-color: #C5C5C5;
$timeline-background-odd: #F7F7F7;
$timeline-background-even: $white;


/**
 * Booking page - Wrappers
 */
$booking_page-background_color_of_form_wrappers : #fff;
$booking_page-border_color_of_form_wrappers     : #ccc;
$booking_page-padding_of_form_wrappers          : 20px;
$booking_page-margin_of_form_wrappers           : 80px;
$booking_page-margin_of_form_buttons            : 90px;


/**
 * Booking page - form fields
 */

$booking_form-label_marginbottom    : 10px;
$booking_form-input_height          : 54px;
$booking_form-input_fontsize        : 1.15em;
$booking_form-input_border_radius   : 3px;
$booking_form-input_font_style      : 700 10px $font-serif;
$booking_form-input_border_style    : 1px solid #BFBFBF;
$booking_form-input_padding         : 12px;
$booking_form-input_disabled_color  : #efefef;
$booking_form-input_disabled_opacity: 0.5;

$inputFieldInnerPadding             : 15px 15px;

/**
 * Reused style component assets
 */

%booking_form-header {
    font-size: 1.3em;
    font-weight: 600;
    padding-bottom: 20px;
}

%booking_form-mandatory_star {
    &:after {
        content: "*";
        color: $mainErrorColor;
        font-size: 0.8em;
        padding-left: 3px;
        top: -3px;
        position: absolute;
    }
}

%car-card-style {
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    //border: 1px solid #d6d6d6;
    margin-top:-12px;
    //border-bottom: 0;
    /*-webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);*/
}

%regno-plate {
    border: 2px solid lighten(#000, 30%);
    border-radius: 4px;
    background: $white;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
    letter-spacing: 1.19px;
    color: lighten(#000, 10%);
    height: 32px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    position: relative;
    overflow:hidden;
    //z-index: 2;
    
    &:before {
        content: '';
        border-radius: 4px 0 0 4px;
        position: absolute;
        background: url('components/icons/RegistrationPlateEUSection.svg') no-repeat left center;
        background-size: 80% 80%;
        width: 23px;
        height: 39px;
        left: -2px;
        top: -5px;
        z-index: 0;
    }
    
}

%top-section-header {
    font-size: 2em;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    font-weight: 300;
}

%now-indicator {
    position: absolute;
    width: 1px;
    border-left: 2px solid $timeline-hud-color;
    top: -1px;
    background: $primary-color;
    z-index: 50;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .5);
}

%global-drop-shadow{
    box-shadow: 0px 5px 7px -3px lighten(#9A9A9A, 12%);
    transition:0.1s;

    &:hover, &:active, &:focus {
        box-shadow: 0px 5px 7px -3px darken(#9A9A9A, 12%) !important;
        cursor: pointer;
    }
}

//Fix for autocomplete highighting
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

